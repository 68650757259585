@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Grandstander:ital,wght@0,100..900;1,100..900&family=Macondo&family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
	margin: 0;
	padding: 0;
	height: 100%;
	font-size: 16px;
	min-height: 100vh;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

@media (max-width: 768px) {
	html {
		font-size: 12px;
	}
}

@media (max-width: 480px) {
	html {
		font-size: 10px;
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* #15513c */
/* #1c295a */

.typewriter span {
	overflow: hidden;
	border: none;
	border-right: 0.1em solid #fde656;
	white-space: nowrap;
	margin: 0;
	letter-spacing: 0.15em;
	animation-delay: 2s;
	animation:
		typing 1.5s steps(50, end),
		blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
	from {
		/* padding: 0; */
		width: 0px;
	}
	to {
		width: 100%;
	}
}

/* The typewriter cursor effect */
@keyframes blink-caret {
	from,
	to {
		border-color: transparent;
	}
	50% {
		border-color: #fde656;
	}
}

.section-title {
	color: #1c295a;
	width: 100%;
	font-size: 2rem;
	text-align: center;
	font-family: Poppins;
	font-weight: 500;

	&::before,
	&::after {
		display: inline-block;
		content: '';
		border-top: 0.2rem solid #fd8100;
		width: 5rem;
		margin: 0 1rem;
		transform: translateY(-0.5rem);
	}
}

@media (max-width: 768px) {
	.section-title::before,
	.section-title::after {
		border-top: 0.15rem solid #fd8100;
		width: 4rem;
		margin: 0 0.5rem;
	}
}

@media (max-width: 480px) {
	.section-title::before,
	.section-title::after {
		width: 2.5rem;
		margin: 0 0.5rem;
	}
}

.section-title-shorter {
	&::before,
	&::after {
		border-top: 0.2rem solid #fd8100;
		width: 0;
		margin: 0 1rem;
		transform: translateY(-0.5rem);
	}
}

.accreditation-card-container {
	.testimonial {
		blockquote {
			background: #efefef;
			padding: 20px 60px;
			position: relative;
			border: none;
			border-radius: 8px;
			font-style: italic;

			&:before,
			&:after {
				content: '\201C';
				position: absolute;
				font-size: 80px;
				line-height: 1;
				color: #757f9a;
				font-style: normal;
				font-family: 'Open Sans', Helvetica, sans-serif;
			}

			&:before {
				top: 0;
				left: 10px;
			}

			&:after {
				content: '\201D';
				right: 10px;
				bottom: -0.5em;
			}
		}

		span {
			width: 0;
			height: 0;
			border-left: 0 solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid #efefef;
			margin: 0 0 0 60px;
			display: block;
		}
	}
}
